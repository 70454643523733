import axios from "axios";
import { API_URL } from "../../../common/Utils/constant";

export const convertXMLUpload = async (currentFile: any, company: string) => {
    const formData = new FormData();
    formData.append('file', currentFile);
    formData.append('company', company);

    try {
        const response = await axios.post(`${API_URL}/upload/xml`, formData);
        const url = window.URL.createObjectURL(new Blob([response.data.file], { type: 'application/xml' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${response.data.fileName}.xml`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return response;
    } catch (error) {
        console.error('Error uploading file:', error);
        alert("File is not Correct!");
    }
}