import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import ECommerce from './pages/Dashboard/ECommerce';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import Loader from './common/Loader';
import MyLead from './pages/MyLead/MyLead';
import Users from './pages/Users/Users';
import LeadDetails from './pages/MyLead/LeadDetails';
import AddUser from './pages/Users/AddUser/AddUser';
import AddLead from './pages/MyLead/AddLead/AddLead';
import EditLead from './pages/MyLead/EditLead/EditLead';
import LeadEmployeeDetails from './pages/MyLead/Employee/LeadEmployeeDetails';
import Protected from './common/Protected/Protected';
import { useDispatch } from 'react-redux';
import { getAllUsers } from './pages/Users/Dependencies/action';
import { getLoginUserDetails } from './pages/Authentication/Dependencies/action';
import AssignOrders from './pages/MyLead/AssignOrders/AssignOrders';
import { getProductList } from './pages/MyLead/Dependencies/action';
import ManageUserOrders from './pages/ManageUserOrders/ManageUserOrders';
import ManageMarkAttendence from './pages/Attendence/ManageMarkAttendence/ManageMarkAttendence';
import LeadTLDetails from './pages/MyLead/TeamLeader/LeadTLDetails';
import EditUser from './pages/Users/EditUser/EditUser';
import UserLogs from './pages/Logs/userLogs';
import XMLConvertor from './pages/XMLConvertor/XMLConvertor';
import LoginFormComponent from './pages/Authentication/LoginBear/LoginBear';

const Profile = lazy(() => import('./pages/Profile'));
const DefaultLayout = lazy(() => import('./layout/DefaultLayout'));

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
    if (localStorage.getItem('skyoffsite_token')) {
      dispatch(getAllUsers() as any);
      dispatch(getProductList() as any);
      dispatch(getLoginUserDetails() as any);
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        {/* <Route path="/" element={<SignIn />} /> */}

        <Route path="/" element={<LoginFormComponent />} />
        {/* <Route path="/auth/signup" element={<SignUp />} /> */}
        <Route path="/dashboard" element={<DefaultLayout />}>
          <Route index element={<ECommerce />} />
          <Route
            path="/dashboard/profile"
            element={
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path="/dashboard/orders"
            element={
              <Suspense fallback={<Loader />}>
                <MyLead />
              </Suspense>
            }
          />

          <Route
            path="/dashboard/orders/add"
            element={
              <Protected access={['ADMIN', 'MANAGER']}>
                <Suspense fallback={<Loader />}>
                  <AddLead />
                </Suspense>
              </Protected>
            }
          />

          <Route
            path="/dashboard/users"
            element={
              <Protected access={['ADMIN', 'MANAGER']}>
                <Suspense fallback={<Loader />}>
                  <Users />
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/dashboard/users/add"
            element={
              <Protected access={['ADMIN', 'MANAGER']}>
                <Suspense fallback={<Loader />}>
                  <AddUser />
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/dashboard/orders/:id"
            element={
              <Protected access={['ADMIN', 'MANAGER']}>
                <Suspense fallback={<Loader />}>
                  <LeadDetails />
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/dashboard/orders/edit/:id"
            element={
              <Protected access={['ADMIN', 'MANAGER']}>
                <Suspense fallback={<Loader />}>
                  <EditLead />
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/dashboard/orders/assign"
            element={
              <Protected access={['ADMIN', 'MANAGER', 'TEAM_LEADER']}>
                <Suspense fallback={<Loader />}>
                  <AssignOrders />
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/dashboard/orders/employee/:id"
            element={
              <Suspense fallback={<Loader />}>
                <LeadEmployeeDetails />
              </Suspense>
            }
          />
          <Route
            path="/dashboard/orders/team_leader/:id"
            element={
              <Suspense fallback={<Loader />}>
                <LeadTLDetails />
              </Suspense>
            }
          />
          <Route
            path="/dashboard/users/manage-orders"
            element={
              <Protected access={['ADMIN', 'MANAGER']}>
                <Suspense fallback={<Loader />}>
                  <ManageUserOrders />
                </Suspense>
              </Protected>
            }
          />

          <Route
            path="/dashboard/users/logs"
            element={
              <Protected access={['ADMIN', 'MANAGER']}>
                <Suspense fallback={<Loader />}>
                  <UserLogs />
                </Suspense>
              </Protected>
            }
          />

          <Route
            path="/dashboard/users/edit/:id"
            element={
              <Protected access={['ADMIN', 'MANAGER']}>
                <Suspense fallback={<Loader />}>
                  <EditUser />
                </Suspense>
              </Protected>
            }
          />

          <Route
            path="/dashboard/mark/atttendence"
            element={
              <Protected access={['ADMIN', 'MANAGER']}>
                <Suspense fallback={<Loader />}>
                  <ManageMarkAttendence />
                </Suspense>
              </Protected>
            }
          />

          <Route
            path="/dashboard/users/xml"
            element={
              <Suspense fallback={<Loader />}>
                <XMLConvertor />
              </Suspense>
            }
          />

        </Route>

      </Routes >
    </>
  );
}

export default App;
