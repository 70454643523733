import React, { useRef, useState } from 'react'
import { convertXMLUpload } from './Dependencies/action';
import DropFileConverter from '../../components/DropFileConverter';

const XMLConvertor = () => {
    return (
        <div className="rounded-sm border border-stroke bg-white px-5 min-h-screen pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 overflow-x-auto">
            <h1 className="text-2xl font-semibold">
                RenoWorks Convertor
            </h1>
            <DropFileConverter company="renoworks" />
            <br />
            <h1 className="text-2xl font-semibold">
                Acculynx Convertor
            </h1>
            <DropFileConverter company="acculynx" />
            <h1 className="text-2xl font-semibold">
                Symbility Convertor
            </h1>
            <DropFileConverter company="symbility" />
        </div>
    )
}

export default XMLConvertor